// src/pages/intranet/dashboard.js

import React, { useEffect, useState } from "react";
import ClientForm from "../../components/ClientForm";
import SplashScreen from "../../components/SplashScreen";
import TranslationBanner from "../../components/TranslationBanner";
import IntranetLayout from "../../components/intranet-layout";
import withAuth from "../../components/withAuth";

const GetInTouchIntranet = () => {
	const [intranetData, setIntranetData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	// Fetch data from intranet endpoint
	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const data = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, data.message);
				return null;
			}

			return data;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const data = await fetchData(
				"intranet?_fields=id,title,content,acf&acf_format=standard"
			);

			if (data) {
				console.log(data);
				setIntranetData(data);

				// Find the "Details" page object
				const detailsPage = data.find(
					(page) => page.title?.rendered === "Get In Touch"
				);

				if (detailsPage) {
					const heroSection = detailsPage?.acf;
					setHeroData({
						heading: heroSection?.heading || "",
						body: heroSection?.body || "",
					});
				}
			} else {
				setIntranetData(null);
			}
		};

		getData();
	}, []);

	const { heading, body } = heroData;

	// Get the "Details" page object again (it should be available now if intranetData is set)
	const detailsPage = intranetData?.find(
		(page) => page.title?.rendered === "Get In Touch"
	);

	const detailsPageNew = intranetData?.find(
		(page) => page.title?.rendered === "Details"
	);

	return (
		<div>
			{intranetData && detailsPage ? (
				<IntranetLayout
					navLinks={detailsPageNew?.acf?.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<TranslationBanner
							languageCode={languageCode}
							onSelectLanguage={handleLanguageChange}
						/>

						<ClientForm
							languageCode={languageCode}
							heading={heading}
							body={body}
							companyName="Intranet"
						/>
					</div>
				</IntranetLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export default withAuth(GetInTouchIntranet, "intranet");
